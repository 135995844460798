define("discourse/plugins/discourse-policy/discourse/components/policy-group-input", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "select-kit/components/group-chooser", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _service, _groupChooser, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _PolicyGroupInput;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let PolicyGroupInput = _exports.default = (_class = (_PolicyGroupInput = class PolicyGroupInput extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
    get selectedGroups() {
      return (this.args.groups || "").split(",").filter(Boolean);
    }
    get availableGroups() {
      return (this.site.groups || []).map(g1 =>
      // prevents group "everyone" to be listed
      g1.id === 0 ? null : g1.name).filter(Boolean);
    }
    onChange(values1) {
      this.args.onChangeGroup?.(values1.join(","));
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <GroupChooser
        @content={{this.availableGroups}}
        @valueProperty={{null}}
        @nameProperty={{null}}
        @value={{this.selectedGroups}}
        @onChange={{this.onChange}}
      />
    
  */
  {
    "id": "k+Z64mu9",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@content\",\"@valueProperty\",\"@nameProperty\",\"@value\",\"@onChange\"],[[30,0,[\"availableGroups\"]],null,null,[30,0,[\"selectedGroups\"]],[30,0,[\"onChange\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-policy/discourse/components/policy-group-input.js",
    "scope": () => [_groupChooser.default],
    "isStrictMode": true
  }), _PolicyGroupInput), _PolicyGroupInput), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype)), _class);
});